import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Dashboard from "../../layouts/Dashboard";
import {
  Box,
  Grid,
  Typography,
  styled,
  Skeleton,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ExpandMoreIcon } from "../../../src/assets/images/Aos/accordianArrow.svg";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import ReportBugBtn from "../../components/Button/ReportBugBtn";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { WorkBookComponent } from "./WorkBook";
import SubjectBtnBtmSheet from "../../components/Button/subjectBtnBtmSheet";
import IconComponent from "../../components/Icons";
import BreadCrumbresComponent from "../../components/BreadCrumbsResponsive";
import { AccordionBtmSheetRes } from "../../components/BottomDrawer/AccordionbtmSheet";
import BorderText from "../../components/Label/BorderText";
import html2canvas from "html2canvas";
import ToastMessage from "../../components/ToastMessage";
import {
  getSubjectAOSDetailsByID,
  markAsComplete,
} from "../../services/api/subject";
import { useLocation } from "react-router-dom";
import { getToken } from "../../services/api/login";
import { ChevronLeft, ChevronRight } from "../../utils/icons";
import Icons from "../../components/Icons";
import StudentVideoV1 from "../../components/Video/StudentVideoV1";

const StyledIconButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: "transparent", // Disable hover color
    fill: "red",
  },
  padding: "2px",
  ":disabled": {
    cursor: "not-allowed",
    "pointer-events": "auto !important",
  },
});
const CustomTypography = styled(Typography)(
  ({ isEmpty }: { isEmpty: boolean }) => ({
    cursor: "default",
    border: "0px",
    fontWeight: "501",
    borderBottom: !isEmpty ? "1px solid" : "0",
    borderColor: "#FFA737",
    minWidth: isEmpty ? "35px" : "auto",
  })
);



export interface SubjectAOSDetail {
  id: number;
  student_id: number;
  subject_id: number;
  subject: string;
  subject_icon: null;
  subject_colour: string;
  mst_subject_group_id: number;
  sub_code: string;
  area_of_study: AreaOfStudy[];
  list_of_aos: ListOfAo[];
}

export interface AreaOfStudy {
  id: number;
  area_of_study: string;
  area_of_study_icon: null;
  area_of_study_description: null;
  task_completed: number;
  total_task: number;
  topics: Topic[];
}

export interface Topic {
  id: number;
  topic: string;
  file_count: number;
  fileTypes: FileType[];
}

export interface FileType {
  id: number;
  file_type_id: number;
  file_type: string;
  sub_type_taxonomy: number;
  ext_type: string;
  fileName: FileName[];
}

export interface FileName {
  id: number;
  fileName: string;
}

export interface ListOfAo {
  id: number;
  area_of_study: string;
}


export const WorkBook = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const verifyAccess = getToken();
  const [subjectAOSDetails, setSubjectAOSDetails] = useState<Array<SubjectAOSDetail>>([]);
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [bugTitle, setBugTitle] = useState<string>("");
  const [bugDescription, setBugDescription] = useState<string>("");
  const [attachment, setAttachment] = useState<(File | string)[]>([]);
  const [aosID, setAosId] = useState<string | undefined>();
  const [totalTasks, setTotalTasks] = useState<number>(0);
  const [completedTasks, setCompletedTasks] = useState<number>(0);
  const [page, setPage] = useState<{
    nextPage: { id: string; area_of_study: string; aos_code: string } | null;
    currentPage: { id: string; area_of_study: string; aos_code: string } | null;
    prevPage: { id: string; area_of_study: string; aos_code: string } | null;
  }>({ nextPage: null, prevPage: null, currentPage: null });
  const [bottomDrawerOpen2, setBottomDrawerOpen2] = useState(false);
  const [selectedSubModule, setSelectedSubModule] = useState<any>({
    id: "",
    module: "",
  });
  console.log("selectedSubModule", page);
  const [recordingData, setRecordingData] = useState<any[]>([]);
  const [bugRepId, setBugRepId] = useState("");
  const [expanded, setExpanded] = useState<Set<string>>(
    new Set()
  );
  const [loadingData, setLoadingData] = useState(true);
  const [currentSubjectId, setCurrentSubjectId] = useState<
    string | undefined
  >();
  const [selectedModuleTypeId, setSelectedModuleTypeId] = useState("");
  // this is to get perticluar file
  const [file_id, setFileId] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<string | null>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: string | null;
  }>({});
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [markTaskId, setMarkTaskId] = useState("");
  const [markComplete, setMarkComplete] = useState(
    recordingData[0]?.files.test_completion_status || false
  );
  const handleBugTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const title = event.target.value;
    setBugTitle(title);
    const isValidTitle = /^(?=.{1,50}$).*$/.test(
      title
    );
    setTitleError(!isValidTitle);
  };

  const handleBugDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const description = event.target.value;
    setBugDescription(description);
    const isValidDescription =
      /^(?=.{1,250}$).*$/.test(description);
    setDescriptionError(!isValidDescription);
  };

  useEffect(() => {
    console.log("selectedItemselectedItems", selectedItem, selectedItems);
  }, [selectedItem, selectedItems]);
  const handleMark = async (id: any, status: any) => {

    const idFile = parseInt(id, 10);
    console.log("taskComplete", idFile);
    try {
      const payload = {
        task_id: idFile,
        test_completion_status: status,
      };
      const response = await markAsComplete(payload, verifyAccess.token);
      console.log("taskComplete", response?.data.data.test_completion_status);
      setMarkComplete(response?.data.data.test_completion_status);
      if (status) {
        setCompletedTasks((prev) => prev - 1)
      } else {
        setCompletedTasks((prev) => prev + 1)
      }
      console.log("file log time", response?.data.data);
    } catch (error) {
      console.error("Error fetching file type details:", error);
    }
  };

  const handleChange =
    (panel: string) =>
      (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
        const newExpanded = new Set<string>(expanded);
        if (isExpanded) {
          newExpanded.add(panel);
        } else {
          newExpanded.delete(panel);
        }
        setExpanded(newExpanded);
      };

  const handleItemClick = (
    subject_id: string,
    aos_id: string,
    topic_id: string,
    file_type_id: string,
    file_id: string,
    ext_type: string,
    file_type: string,
    topic_name: string
  ) => {
    const updatedSelectedItems: { [key: string]: string | null } = {
      ...selectedItems,
    };

    updatedSelectedItems[`panel${topic_id}`] = file_id;
    console.log(
      "ext_type",
      ext_type,
      "file_type",
      file_type,
      "topic_name",
      topic_name,
      "topic_id",
      topic_id,
      "sub_module_id",
      file_type_id,
      "aos_id",
      aos_id,
      "subject_id",
      subject_id,
      "file_id",
      file_id,
      "subjectAOSDetails",
      subjectAOSDetails,
      "updatedSelectedItems",
      updatedSelectedItems
    );

    setShowRightPanel(true);
    const newSearch = new URLSearchParams();
    newSearch.set("sid", subject_id);
    newSearch.set("aosID", aos_id);
    newSearch.set("topicID", topic_id);
    newSearch.set("typeID", file_type_id);
    newSearch.set("fileID", file_id);
    navigate(`?${newSearch.toString()}`);
  };
  const SetRequiredData = (
    subjectAOSDetailData: any,
    topicID: any,
    typeID: any,
    fileID: any
  ) => {
    setSubjectAOSDetails(subjectAOSDetailData);
    setTotalTasks(subjectAOSDetailData[0]?.area_of_study[0].total_task)
    setCompletedTasks(subjectAOSDetailData[0]?.area_of_study[0].task_completed)
    const list_of_aos =
      subjectAOSDetailData && subjectAOSDetailData[0]?.list_of_aos;
    const currentAosID = queryParams.get("aosID");

    const currentAosIDIndex = list_of_aos
      .findIndex((va: any) => va.id === parseInt(currentAosID || ""));
    setPage((prev) => ({
      ...prev,
      currentPage: list_of_aos[currentAosIDIndex],
    }));
    console.log("list_of_aos", JSON.stringify(list_of_aos))
    const nextIndex = currentAosIDIndex + 1;
    const prevIndex = currentAosIDIndex - 1;
    if (
      nextIndex <= list_of_aos.length - 1 &&
      nextIndex >= 0 &&
      nextIndex !== currentAosIDIndex
    ) {
      setPage((prev) => ({ ...prev, nextPage: list_of_aos[nextIndex] }));
    } else {
      setPage((prev) => ({ ...prev, nextPage: null }));
    }
    if (
      prevIndex <= list_of_aos.length - 1 &&
      prevIndex >= 0 &&
      prevIndex !== currentAosIDIndex
    ) {
      setPage((prev) => ({ ...prev, prevPage: list_of_aos[prevIndex] }));
    } else {
      setPage((prev) => ({ ...prev, prevPage: null }));
    }
    let subjectAOSDetail = [subjectAOSDetailData[0].area_of_study[0].topics[0]];
    if (topicID !== null) {
      subjectAOSDetail = subjectAOSDetailData[0].area_of_study[0].topics.filter(
        (va: any) => parseInt(va.id) === parseInt(topicID)
      );
    }
    console.log(subjectAOSDetail, "subjectAOSDetailData");
    subjectAOSDetail.forEach((data: any) => {
      let fileTypes = data.fileTypes;
      if (typeID !== null && fileID !== null) {
        fileTypes = data.fileTypes.filter(
          (va: any) => parseInt(va.id) === parseInt(fileID)
        );
      }
      console.log(fileTypes, "fileTypesData");

      const firstTopicId = data.id;
      const firstAccordionId = `panel${firstTopicId}`;
      const firstAccordionDetailsId = `panel${firstTopicId}-details`;

      const expandedIds = new Set<string>([
        firstAccordionId,
        firstAccordionDetailsId,
      ]);

      setExpanded(expandedIds);
      setSelectedItem(fileTypes[0]?.ext_type);
      setSelectedItems({
        [`panel${firstTopicId}`]: fileTypes[0]?.id,
      });
      setSelectedSubModule({
        id: data?.id,
        module: data?.topic,
      });
      setSelectedType(fileTypes[0]?.file_type);
      setSelectedModuleTypeId(fileTypes[0]?.file_type_id);
      if (fileID !== null) {
        setFileId(fileID);
      } else {
        setFileId(fileTypes[0]?.fileName[0].id);
      }
    });
    setLoadingData(false);
  };
  useEffect(() => {
    const cSubjectId = queryParams.get("sid");
    const caosID = queryParams.get("aosID");
    const topicID = queryParams.get("topicID");
    const typeID = queryParams.get("typeID");
    const fileID = queryParams.get("fileID");

    // Avoid updating aosID if it's the same
    if (aosID !== caosID) {
      setAosId(caosID !== null ? caosID : undefined);
    }

    // Avoid updating subject ID if it's the same
    if (currentSubjectId !== cSubjectId) {
      setCurrentSubjectId(cSubjectId !== null ? cSubjectId : undefined);
    }

    // Fetch or set data only if necessary
    if (subjectAOSDetails.length === 0 || caosID !== aosID) {
      // Fetch data when subjectAOSDetails is empty or AOS ID changes
      getSubjectAOSDetailsById(cSubjectId, caosID).then((response: any) => {
        const subjectAOSDetailData = response?.data?.data;
        if (subjectAOSDetailData.length > 0) {
          SetRequiredData(subjectAOSDetailData, topicID, typeID, fileID);
        }
      });
    } else if (subjectAOSDetails.length > 0 && topicID && typeID && fileID) {
      // Use available data if it's already fetched
      SetRequiredData(subjectAOSDetails, topicID, typeID, fileID);
    }
  }, [location.search]); // Only re-run when URL changes

  const getSubjectAOSDetailsById = async (subject_id: any, aos_id: any) => {
    try {
      const payload = {
        subject_id: subject_id,
        aos_id: aos_id,
      };
      const response = await getSubjectAOSDetailsByID(
        payload,
        verifyAccess.token
      );
      return response;
      // }
    } catch (error) {
      setLoadingData(false);
      console.error("Error fetching subject aos details by ID:", error);
    }
  };

  const navigate = useNavigate();
  const handleClickSub = (subCode: string) => {
    navigate(`/subject/${subCode}?sid=${subjectAOSDetails[0]?.subject_id}`);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => handleClickSub(subjectAOSDetails[0]?.sub_code)}
      sx={{
        fontSize: 12,
        color: "#333",
        fontFamily: "Inter",
        fontWeight: 600,
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      {subjectAOSDetails &&
        subjectAOSDetails[0]?.sub_code
      }
    </Link>,
    <Typography
      key="3"
      sx={{
        fontSize: 12,
        color: "#333",
        fontFamily: "Inter",
        fontWeight: 500,
      }}
    >
      AOS 2 [name]
    </Typography>,
  ];

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 5,
    marginTop: "8px",
    width: "131px",

    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
      border: "1px solid #D2D2D2",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
      border: "1px solid #308fe8",
    },
  }));

  const theme = useTheme();

  const handleExpandAll = () => {
    const allPanelIds = new Set<string>(
      subjectAOSDetails[0]?.area_of_study[0]?.topics?.map(
        (item: any) => `panel${item.id}`
      )
    );
    setExpanded(allPanelIds);
  };

  const handleCollapseAll = () => {
    setExpanded(new Set());
  };

  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: "image/png" });
  };

  const captureScreenshot = () => {
    let element = document.getElementById("videoSec");
    if (isMobile) {
      element = document.getElementById("videoSec2");
    }
    if (element) {
      const overlay = document.createElement("div");
      overlay.id = "highlightOverlay";
      overlay.style.width = `${element.offsetWidth}px`;
      overlay.style.height = `${element.offsetHeight}px`;
      overlay.style.top = `${element.offsetTop}px`;
      overlay.style.left = `${element.offsetLeft}px`;
      overlay.style.backgroundColor = "rgba(0, 122, 255, 0.7)";
      overlay.style.borderRadius = "16px";
      overlay.style.position = "absolute";
      overlay.style.zIndex = "99";
      overlay.style.display = "flex";
      overlay.style.justifyContent = "center";
      overlay.style.alignItems = "center";

      document.body.appendChild(overlay);

      const cameraIcon = document.createElement("div");
      cameraIcon.id = "cameraIcon";
      const iconComponent = (
        <IconComponent
          name="cameraOutline"
          fill="#FFF"
          width={146}
          height={146}
          style={{ opacity: "0.7" }}
        />
      );
      ReactDOM.render(iconComponent, cameraIcon);
      overlay.appendChild(cameraIcon);

      html2canvas(element).then((canvas) => {
        const dataUrl = canvas.toDataURL();
        const blob = dataURItoBlob(dataUrl);
        const file = new File(
          [blob],
          `screenshot_${attachment.length + 1}.png`,
          { type: "image/png" }
        );
        const reader = new FileReader();
        reader.onloadend = () => {
          console.error("dataUrl", reader.result);
        };
        reader.readAsDataURL(file);
        setAttachment([...attachment, file]);
        setTimeout(() => {
          document.body.removeChild(overlay);
        }, 700);
      });
    } else {
      console.error("Element not found");
    }
  };

  function hexToRgb(hex: string): number[] | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
      return null;
    }
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ];
  }

  const checkDisable = (type: string) => {
    const list_of_aos = subjectAOSDetails && subjectAOSDetails[0]?.list_of_aos;
    const currentAosID = queryParams.get("aosID");
    const filterdlist = list_of_aos
      .filter((va: any) => parseInt(va.id) !== parseInt(currentAosID || ""))
    console.log("checkdisable", list_of_aos, filterdlist, currentAosID);
    const currentAosIDIndex = list_of_aos
      .findIndex((va: any) => parseInt(va.id) === parseInt(currentAosID || ""));
    if (type === "next") {
      if (list_of_aos.length === 0 || list_of_aos.length === 1) {
        return true;
      } else {
        return currentAosIDIndex === list_of_aos.length - 1 ? true : false;
      }
    }
    if (type === "prev") {
      if (list_of_aos.length === 0 || list_of_aos.length === 1) {
        return true;
      } else {
        return currentAosIDIndex === 0 ? true : false;
      }
    }
  };
  const handleAOS = (type: string) => {
    const list_of_aos = subjectAOSDetails && subjectAOSDetails[0]?.list_of_aos;
    const currentAosID = queryParams.get("aosID");
    const currentAosIDIndex = list_of_aos
      .findIndex((va: any) => va.id === parseInt(currentAosID || ""));
    console.log("handleAOS", currentAosIDIndex, list_of_aos);
    setSelectedSubModule({ id: "", module: "" });

    setSelectedItem("");
    setSelectedType("");
    setSelectedModuleTypeId("");
    setSelectedItems({});
    setFileId("");
    if (type === "next") {
      if (currentAosIDIndex < list_of_aos.length - 1) {
        const nextIndex = currentAosIDIndex + 1;
        console.log("handleAOS222", currentAosIDIndex, nextIndex, list_of_aos);
        const newSearch = new URLSearchParams();
        newSearch.set("sid", `${subjectAOSDetails[0]?.subject_id}`);
        newSearch.set("aosID", `${list_of_aos[nextIndex].id}`);
        navigate(`?${newSearch.toString()}`);
      }
    }
    if (type === "prev") {
      if (currentAosIDIndex > 0) {
        console.log("handleAOS111", currentAosIDIndex, list_of_aos);
        const prevIndex = currentAosIDIndex - 1;
        const newSearch = new URLSearchParams();
        newSearch.set("sid", `${subjectAOSDetails[0]?.subject_id}`);
        newSearch.set("aosID", `${list_of_aos[prevIndex].id}`);
        navigate(`?${newSearch.toString()}`);
      }
    }
  };

  const rgbColor = hexToRgb(subjectAOSDetails[0]?.subject_colour);
  const opacity = 0.1;
  const handleDropdownSubject = (value: any) => {
    const newSearch = new URLSearchParams();
    newSearch.set("sid", value);
    // Push new URL with updated query parameter
    navigate(
      `/subject/${subjectAOSDetails[0]?.sub_code}?${newSearch.toString()}`
    );
  };

  return (
    <Dashboard
      showPopover={true}
      selectedSubjectId={currentSubjectId}
      onClickCallback={(value) => handleDropdownSubject(value)}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={{ md: "calc(100vh - 76px)" }}
        overflow={{ md: "hidden" }}
      >
        {toast && (
          <ToastMessage
            type={toast.type as "success" | "error"}
            message={toast.message}
            onClose={() => setToast(null)}
            style={{
              position: "absolute",
              top: "85px",
            }}
          />
        )}
        {isMobile && <SubjectBtnBtmSheet currentSubjectId={currentSubjectId} />}
        <Box>
          <Grid container sx={{ height: "100%" }}>
            <Grid
              item
              md={4}
              sm={12}
              xs={12}
              style={{ display: isMobile && showRightPanel ? "none" : "block" }}
            >
              <Box
                sx={{
                  boxShadow: "-10px 0px 26px 0px rgba(0, 0, 0, 0.04) inset",
                }}
              >
                <Box
                  sx={{
                    background: `rgba(${rgbColor?.join(",")}, ${opacity})`,
                    padding: isMobile ? "22px 28px" : "25px 45px",
                  }}
                >
                  {loadingData ? (
                    <Skeleton
                      variant="rounded"
                      width={"100%"}
                      height={"35px"}
                    />
                  ) : (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{ marginBottom: "10px" }}
                    >
                      <Breadcrumbs
                        sx={{
                          ".MuiBreadcrumbs-separator": { margin: "0 12px" },
                        }}
                        separator="›"
                        aria-label="breadcrumb"
                      >
                        {breadcrumbs.slice(0, breadcrumbs.length - 1)}
                        <Typography
                          sx={{
                            fontSize: 12,
                            color: "#333",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            textTransform: "capitalize"
                          }}
                        >
                          <span style={{ textTransform: "uppercase" }}>
                            {page.currentPage?.aos_code || "AOS"}
                          </span>{" "}
                          {
                            page.currentPage?.area_of_study == null ? '' : <span>- {page.currentPage?.area_of_study}</span>
                          }
                        </Typography>
                      </Breadcrumbs>
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginBottom: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {loadingData ? (
                      <Skeleton
                        sx={{ marginTop: "15px" }}
                        variant="rounded"
                        width={"100%"}
                        height={"35px"}
                      />
                    ) : (
                      <>
                        {subjectAOSDetails[0]?.area_of_study[0]
                          ?.area_of_study_icon !== null && (
                            <Box
                              borderRadius={"50px"}
                              width={{ md: "35px", xs: "30px", sm: "30px" }}
                              height={{ md: "35px", xs: "30px", sm: "30px" }}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{
                                background: subjectAOSDetails[0]?.subject_colour,
                              }}
                            >
                              <img
                                src={
                                  subjectAOSDetails[0]?.area_of_study[0]
                                    ?.area_of_study_icon
                                }
                                alt="Subject Icon"
                                width={isMobile ? 15 : 23}
                                height={isMobile ? 15 : 23}
                                crossOrigin="anonymous"
                              />
                            </Box>
                          )}
                        {subjectAOSDetails[0]?.area_of_study[0]
                          ?.area_of_study_icon === null &&
                          subjectAOSDetails[0]?.subject_icon !== null && (
                            <Box
                              borderRadius={"50px"}
                              width={{ md: "35px", xs: "30px", sm: "30px" }}
                              height={{ md: "35px", xs: "30px", sm: "30px" }}
                              display={"flex"}
                              alignItems={"baseline"}
                              justifyContent={"center"}
                              sx={{
                                background:
                                  subjectAOSDetails[0]?.subject_colour,
                              }}
                            >
                              <img
                                src={subjectAOSDetails[0]?.subject_icon}
                                alt="Subject Icon"
                                width={15}
                                height={15}
                                style={{ paddingTop: "10px" }}
                                crossOrigin="anonymous"
                              />
                            </Box>
                          )}
                        {subjectAOSDetails[0]?.area_of_study[0]
                          ?.area_of_study_icon === null &&
                          subjectAOSDetails[0]?.subject_icon === null && (
                            <Box
                              borderRadius={"50px"}
                              width={{ md: "35px", xs: "30px", sm: "30px" }}
                              height={{ md: "35px", xs: "30px", sm: "30px" }}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{
                                background: "transparent",
                              }}
                            >
                              <Icons
                                name="bookOpen"
                                width={isMobile ? 30 : 40}
                                height={isMobile ? 30 : 40}
                              />
                            </Box>
                          )}
                        {subjectAOSDetails &&
                          subjectAOSDetails[0]?.list_of_aos.length > 1 && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <div
                                style={{
                                  transition: "opacity 0.1s ease",
                                  opacity: checkDisable("prev") ? 0.5 : 1,
                                  width: "28px",
                                  height: "28px"
                                }}
                              >
                                {!checkDisable("prev") && (
                                  <StyledIconButton
                                    disabled={checkDisable("prev")}
                                    onClick={() => handleAOS("prev")}
                                    aria-label="Previous"
                                  >
                                    <ChevronLeft style={{ color: "green" }} />
                                  </StyledIconButton>
                                )}
                              </div>
                              <CustomTypography
                                variant="caption"
                                isEmpty={page.prevPage === null}
                                onClick={() => handleAOS("prev")}
                                sx={{
                                  cursor: "pointer",
                                  opacity: page.prevPage === null ? 0.5 : 1,
                                  transition: "opacity 0s",
                                  '&.MuiTypography-root': {
                                    marginLeft: "0 !important",
                                    padding: "0 4px"
                                  }
                                }}
                              >
                                {page.prevPage?.aos_code || ""}
                              </CustomTypography>
                              <CustomTypography
                                variant="caption"
                                isEmpty={page.nextPage === null}
                                onClick={() => handleAOS("next")}
                                sx={{
                                  cursor: "pointer",
                                  opacity: page.nextPage === null ? 0.5 : 1,
                                  transition: "opacity 0s",
                                  '&.MuiTypography-root': {
                                    marginLeft: "4px !important",
                                    padding: "0 4px"
                                  }
                                }}
                              >
                                {page.nextPage?.aos_code || ""}
                              </CustomTypography>
                              <Box
                                sx={{
                                  transition: "opacity 0.1s ease",
                                  opacity: checkDisable("next") ? 0.5 : 1,
                                  width: "28px",
                                  height: "28px",
                                  '&.MuiBox-root': {
                                    marginLeft: "0px !important",
                                  }
                                }}
                              >{!checkDisable("next") && (
                                <StyledIconButton
                                  disabled={checkDisable("next")}
                                  onClick={() => handleAOS("next")}
                                  aria-label="Next"
                                >
                                  <ChevronRight />
                                </StyledIconButton>
                              )}
                              </Box>
                              {/* )} */}
                            </Stack>
                          )}
                      </>
                    )}
                  </Box>

                  {loadingData ? (
                    <Skeleton
                      sx={{ marginTop: "15px" }}
                      variant="rounded"
                      width={"100%"}
                      height={"35px"}
                    />
                  ) : (
                    <>
                      {subjectAOSDetails &&
                        subjectAOSDetails[0]?.area_of_study?.map((tab: any) => (
                          <div key={tab?.id}>
                            <Typography
                              sx={{
                                marginBottom: "12px",
                                fontSize: 28,
                                color: "#333",
                                fontWeight: 600,
                                textTransform: "capitalize",
                              }}
                            >
                              {
                                tab.area_of_study == null ? '' : <span>{tab.area_of_study}</span>
                              }
                            </Typography>
                            <Typography
                              sx={{
                                marginBottom: "12px",
                                fontSize: 16,
                                fontFamily: "Inter",
                                fontWeight: 400,
                              }}
                            >
                              {tab.area_of_study_description}
                            </Typography>
                            <Box
                              marginLeft={"auto"}
                              sx={{
                                display: {
                                  md: "block",
                                  sm: "block",
                                  xs: "block",
                                },
                              }}
                            >
                              {totalTasks !== 0 && (
                                <>
                                  <Typography
                                    component={"div"}
                                    variant="detail"
                                    color={"#333333"}
                                    fontWeight={500}
                                    lineHeight={"15px"}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    {`${completedTasks}/${totalTasks} ${totalTasks || 0 > 1 ? "Resources" : "Resource"
                                      } Complete`}
                                  </Typography>
                                  <BorderLinearProgress
                                    variant="determinate"
                                    value={
                                      ((completedTasks || 0) /
                                        (totalTasks || 1)) *
                                      100
                                    }
                                  />
                                </>
                              )}
                            </Box>
                          </div>
                        ))}
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    padding: "24px",
                    height: {
                      md: "100vh",
                      sm: "calc(100vh - 400px)",
                      xs: "calc(100vh - 400px)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        md: "calc(100vh - 385px)",
                        sm: "100%",
                        xs: "100%",
                      },
                      overflowY: { md: "scroll", sm: "scroll", xs: "scroll" },
                    }}
                  >
                    {loadingData ? (
                      <Skeleton
                        sx={{ marginTop: "15px" }}
                        variant="rounded"
                        width={"100%"}
                        height={"100%"}
                      />
                    ) : (
                      <>
                        {subjectAOSDetails &&
                          subjectAOSDetails[0]?.area_of_study[0]?.topics?.map(
                            (sub_module: any, i: number) =>
                              sub_module.file_count > 0 && (
                                <Accordion
                                  key={sub_module.topic + i}
                                  expanded={expanded.has(`panel${sub_module.id}`)}
                                  onChange={handleChange(`panel${sub_module.id}`)}
                                  sx={{
                                    border: "none",
                                    overflowY: "auto",
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${sub_module.id}-content`}
                                    id={`panel${sub_module.id}-header`}
                                    sx={{
                                      borderRadius: "8px",
                                      border: "1px solid #EFEFEF",
                                      background: "#fff",
                                      padding: "16px 20px",
                                      backgroundColor: expanded.has(
                                        `panel${sub_module.id}`
                                      )
                                        ? "#FFEDD1"
                                        : "inherit",
                                      height: "auto",
                                      color: expanded.has(`panel${sub_module.id}`)
                                        ? "#333333"
                                        : "#696969",
                                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                                      {
                                        transform: "rotate(0deg)",
                                      },
                                      ".MuiAccordionSummary-content": {
                                        margin: "0px !important"
                                      },
                                    }}
                                  >
                                    <Box
                                      display={"flex"}
                                      flexDirection={"column"}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 16,
                                          fontFamily: "Inter",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {sub_module.topic}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: 14,
                                          fontFamily: "Inter",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {sub_module.file_count}{" "}
                                        {sub_module.file_count === 1
                                          ? "Resource"
                                          : "Resources"}
                                      </Typography>
                                    </Box>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{ borderRadius: "8px", padding: "0" }}
                                  >
                                    <Box>
                                      {sub_module?.fileTypes.map(
                                        (listItem: any, index: number) => (
                                          <Box
                                            key={index}
                                            onClick={() =>
                                              handleItemClick(
                                                `${subjectAOSDetails[0]?.subject_id}`,
                                                `${subjectAOSDetails[0]
                                                  ?.area_of_study[0]?.id}`,
                                                sub_module?.id,
                                                listItem?.file_type_id,
                                                listItem?.id,
                                                listItem?.ext_type,
                                                listItem?.file_type,
                                                sub_module?.topic
                                              )
                                            }
                                            sx={{
                                              padding: "12px 20px",
                                              marginTop: "5px",
                                              fontSize: "14px",
                                              fontFamily: "Inter",
                                              fontWeight:
                                                selectedItems[
                                                  `panel${sub_module.id}`
                                                ] === listItem?.id
                                                  ? "600"
                                                  : "400",
                                              backgroundColor:
                                                selectedItems[
                                                  `panel${sub_module.id}`
                                                ] === listItem?.id
                                                  ? "#FFC071"
                                                  : "transparent",
                                              borderRadius: "8px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              textTransform: "capitalize",
                                              gap: "8px",
                                            }}
                                          >
                                            {selectedItems[
                                              `panel${sub_module.id}`
                                            ] === listItem?.id ? (
                                              <>
                                                {listItem?.ext_type ===
                                                  "video" ? (
                                                  <IconComponent
                                                    name="recordingBarBlackIcon"
                                                    fill={"#8E8D8A"}
                                                    width={16}
                                                    height={16}
                                                  />
                                                ) : (
                                                  <IconComponent
                                                    name="textFileActive"
                                                    fill={"#8E8D8A"}
                                                    width={16}
                                                    height={16}
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {listItem?.ext_type ===
                                                  "video" ? (
                                                  <IconComponent
                                                    name="recordingBarIcon"
                                                    fill={"#8E8D8A"}
                                                    width={16}
                                                    height={16}
                                                  />
                                                ) : (
                                                  <IconComponent
                                                    name="textFile"
                                                    fill={"#8E8D8A"}
                                                    width={16}
                                                    height={16}
                                                  />
                                                )}
                                              </>
                                            )}
                                            {listItem?.file_type}
                                          </Box>
                                        )
                                      )}
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              )
                          )}
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      padding: "12px 0",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "15px",
                      position: "fixed",
                      bottom: 0,
                      left: "0",
                      right: { md: "inherit", sm: "0", xs: "0" },
                      background: "#fff",
                      boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.16)",
                      zIndex: 999,
                      [theme.breakpoints.up("md")]: {
                        display: "flex",
                        maxWidth: "33.33%",
                      },
                      [theme.breakpoints.down("sm")]: {
                        display: "flex",
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <div>
                      <BorderText
                        text={"Expand all"}
                        onClick={handleExpandAll}
                      />
                    </div>
                    <Box sx={{ marginRight: "12px !important" }}>
                      <BorderText
                        text={"Collapse all"}
                        onClick={handleCollapseAll}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={8}
              sm={12}
              xs={12}
              style={{
                display: isMobile
                  ? showRightPanel
                    ? "block"
                    : "none"
                  : "block",
              }}
              sx={{
                "@media screen and (max-width:900px)": {
                  maxWidth: "100%",
                },
              }}
            >
              <Box
                sx={{
                  "@media screen and (max-width:1240px)": {
                    padding: "25px 10px",
                  },
                  padding: {
                    xs: "0px 0px",
                    md: "25px 45px",
                    sm: "12px 20px",
                  },
                }}
              >
                <div key={selectedSubModule.id}>
                  {selectedItem?.toLowerCase() === "video" && (
                    <div
                      style={{
                        height: "calc(100vh - 127px)",
                        overflowY: "scroll",
                      }}
                    >
                      <BreadCrumbresComponent
                        subjectAOSDetails={subjectAOSDetails}
                        selectedSubModule={selectedSubModule}
                        selectedType={selectedType}
                        setShowRightPanel={setShowRightPanel}
                      />
                      <Box
                        sx={{
                          display: { md: "flex", sm: "none", xs: "none" },
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "24px",
                        }}
                      >
                        <Typography
                          variant="title1"
                          sx={{ fontSize: "22px", color: "#2B3258" }}
                        >
                          {selectedSubModule?.module.replace('-', '')}
                          {selectedType && ' - ' + selectedType.charAt(0).toUpperCase()}
                          {selectedType && selectedType.substring(1)}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <ReportBugBtn
                            bugTitle={bugTitle}
                            bugDescription={bugDescription}
                            attachment={attachment}
                            handleBugTitleChange={handleBugTitleChange}
                            handleBugDescriptionChange={
                              handleBugDescriptionChange
                            }
                            open={open}
                            setOpen={setOpen}
                            openBottomSheet={openBottomSheet}
                            setOpenBottomSheet={setOpenBottomSheet}
                            titleError={titleError}
                            descriptionError={descriptionError}
                            bugRepId={bugRepId}
                            captureScreenshot={captureScreenshot}
                            setAttachment={setAttachment}
                            setToast={setToast}
                            setBugTitle={setBugTitle}
                            setBugDescription={setBugDescription}
                            selectedSubModule={selectedSubModule}
                            selectedType={selectedType ?? ""}
                          />
                          {recordingData[0]?.files
                            .student_acknowledgement_status && (
                              <Button
                                onClick={() => handleMark(markTaskId, markComplete)}
                                sx={{
                                  marginLeft: "10px",
                                  border: markComplete
                                    ? "1px solid #007634"
                                    : "1px solid #FFA737",
                                  padding: "14px",
                                  height: "34px",
                                  textTransform: "capitalize",
                                  background: markComplete ? "#E6F6ED" : "#FFF",
                                  borderRadius: "8px",
                                  ":hover": { background: "#FFEDD1" },
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                {markComplete && (
                                  <IconComponent
                                    name="greenCheckCircle"
                                    fill={"#8E8D8A"}
                                    width={16}
                                    height={16}
                                  />
                                )}
                                {markComplete ? (
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: "#333",
                                      fontFamily: "Inter",
                                      fontWeight: 600,
                                      lineHeight: "21px",
                                    }}
                                  >
                                    Completed
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: "#333",
                                      fontFamily: "Inter",
                                      fontWeight: 600,
                                      lineHeight: "21px",
                                      width: "125px"
                                    }}
                                  >
                                    Mark as complete
                                  </Typography>
                                )}
                              </Button>
                            )}
                        </Box>
                      </Box>
                      <AccordionBtmSheetRes
                        bugDescription={bugDescription}
                        selectedModuleFileData={
                          recordingData[0]?.files.student_acknowledgement_status
                        }
                        bottomDrawerOpen2={bottomDrawerOpen2}
                        setBottomDrawerOpen2={setBottomDrawerOpen2}
                        selectedPassword={recordingData[0]?.files.file_password}
                        markComplete={markComplete}
                        handleMark={handleMark}
                        fileTaskId={markTaskId}
                        bugRepId={bugRepId}
                        bugTitle={bugTitle}
                        setToast={setToast}
                        attachment={attachment}
                        handleBugTitleChange={handleBugTitleChange}
                        handleBugDescriptionChange={handleBugDescriptionChange}
                        titleError={titleError}
                        descriptionError={descriptionError}
                        captureScreenshot={captureScreenshot}
                        setAttachment={setAttachment}
                        setBugTitle={setBugTitle}
                        setBugDescription={setBugDescription}
                        selectedSubModule={selectedSubModule}
                        selectedType={selectedType}
                        subjectAOSDetails={subjectAOSDetails}

                      />
                      {currentSubjectId && aosID && (
                        <Box id='videoSec'>
                          <Box id='videoSec2'>
                            <StudentVideoV1
                              bottomDrawerOpen2={bottomDrawerOpen2}
                              topic_id={selectedSubModule.id}
                              aos_id={aosID}
                              bugRepId={bugRepId}
                              subject_id={currentSubjectId}
                              selectedTopic={selectedSubModule}
                              selectedModuleTypeId={selectedModuleTypeId}
                              setBugRepId={setBugRepId}
                              title={selectedSubModule?.module}
                              setRecordingData={setRecordingData}
                              setMarkComplete={setMarkComplete}
                              file_id={file_id}
                              setMarkTaskId={setMarkTaskId}
                              selectedType={selectedType}
                              subjectAOSDetails={subjectAOSDetails}
                              open={open}
                              openBottomSheet={openBottomSheet}
                              pageSelected={page}
                            />
                          </Box>
                        </Box>
                      )}
                    </div>
                  )}
                  {currentSubjectId &&
                    aosID &&
                    selectedItem?.toLowerCase() === "pdf" && (
                      <WorkBookComponent
                        bottomDrawerOpen2={bottomDrawerOpen2}
                        setBottomDrawerOpen2={setBottomDrawerOpen2}
                        topic_id={selectedSubModule.id}
                        aos_id={aosID}
                        captureScreenshot={captureScreenshot}
                        subject_id={currentSubjectId}
                        selectedTopic={selectedSubModule}
                        selectedModuleTypeId={selectedModuleTypeId}
                        file_id={file_id}
                        selectedSubModule={selectedSubModule}
                        setBugRepId={setBugRepId}
                        selectedType={selectedType ?? ""}
                        bugTitle={bugTitle}
                        bugDescription={bugDescription}
                        attachment={attachment}
                        handleBugTitleChange={handleBugTitleChange}
                        handleBugDescriptionChange={handleBugDescriptionChange}
                        titleError={titleError}
                        descriptionError={descriptionError}
                        bugRepId={bugRepId}
                        setBugTitle={setBugTitle}
                        setBugDescription={setBugDescription}
                        setAttachment={setAttachment}
                        setToast={setToast}
                        subjectAOSDetails={subjectAOSDetails}
                        setShowRightPanel={setShowRightPanel}
                        open={open}
                        openBottomSheet={openBottomSheet}
                        setOpen={setOpen}
                        setOpenBottomSheet={setOpenBottomSheet}
                        setCompletedTasks={setCompletedTasks}
                      />
                    )}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dashboard>
  );
};
